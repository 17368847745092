import React from "react";
import PropTypes from "prop-types";
import { Link, StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import Column from "src/components/Column";
import Tab from "src/components/Tab";
import Row from "src/components/Row";

import css from "./index.module.scss";


const TestCentreNavigation = ({location, page})  => {
  
  const renderIconComponent = Component => {
    return <Component className={css.tabIcon}/>;
  };
  
  const isActiveTab = path => {
    return location === path;
  };
  
  return (
    <StaticQuery
      query={graphql`
        query {
          logo: file(relativePath: { eq: "images/test-centre/logo.png" }) {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => (
        <Column direction="cc">
          <Link to='/' className={css.logoImg}>
            <Img fluid={data.logo.childImageSharp.fluid}/>
          </Link>
          <Row className={css.nestedTabs}>
            <Link to={page.path}>
              <Tab className={isActiveTab(page.path) ? css.tabActive : css.tabDefault}>
                {renderIconComponent(page.icon.component)}
                <p>{page.icon.text}</p>
              </Tab>
            </Link>
            <Link to={page.pages.inspections.path}>
              <Tab className={isActiveTab(page.pages.inspections.path) ? css.tabActive : css.tabDefault}>
                {renderIconComponent(page.pages.inspections.icon.component)}
                <p>{page.pages.inspections.icon.text}</p>
              </Tab>
            </Link>
            <Link to={page.pages.bookings.path}>
              <Tab className={isActiveTab(page.pages.bookings.path) ? css.tabActive : css.tabDefault}>
                {renderIconComponent(page.pages.bookings.icon.component)}
                <p>{page.pages.bookings.icon.text}</p>
              </Tab>
            </Link>
          </Row>
        </Column>
      )}
    />
  );
};

TestCentreNavigation.propTypes = {
  page: PropTypes.object.isRequired,
  location: PropTypes.string.isRequired
};

export default TestCentreNavigation;
