import React from "react";

import BookingForm from "src/containers/BookingForm";
import TestCentreNavigation from "src/containers/TestCentreNavigation";
import {withSEO} from "src/hoc/SEO";
import { pages } from "src/config";


const BookingsPage = props => {
  return (
    <main>
      <TestCentreNavigation page={pages.testCentre} location={props.location.pathname}/>
      <BookingForm page={pages.testCentre.pages.bookings}/>
    </main>
  );
};

export default withSEO({
  title: pages.testCentre.pages.bookings.title
})(BookingsPage);
