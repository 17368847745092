import React from "react";
import PropTypes from "prop-types";
import Loader from 'react-loader-spinner'
import moment from 'moment';
import dateFnsFormat from 'date-fns/format';

import Column from "src/components/Column";
import DatePicker from "src/components/DatePicker";
import TimePicker from "src/components/TimePicker";
import ToggleSwitch from "src/components/ToggleSwitch";
import Row from "src/components/Row";

import css from "./index.module.scss";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"


class BookingForm extends React.Component {
  state = this.getInitialState();
  
  getInitialState() {
    return {
      activeSwitch: 'middleOption',
      date: null,
      email: '',
      errors: {
        date: false,
        email: false,
        firstName: false,
        lastName: false,
        phoneNumber: false,
        registration: false,
        vehicleClass: false
      },
      firstName: '',
      honeyPot: '',
      lastName: '',
      levy: null,
      phoneNumber: '',
      testFee: null,
      time: moment().hour(9).minute(0).format("HH:mm"),
      registrationYear: '',
      registrationCounty: '',
      registrationID: '',
      submitted: false,
      submitting: false,
      vehicleClass: 'Select...'
    }
  }
  
  handleDateChange(date) {
    const {errors} = this.state;
    this.setState({date: dateFnsFormat(date, "E dd/MM/yyyy"), errors: {...errors, date: false}});
  }
  
  handleErrorCheck(event) {
    const {target: {name, value}} = event;
    if(name !== 'honeypot') {
      this.errorCheck(name, value);
    }
  }
  
  errorCheck(key, value) {
    const {errors} = this.state;
    
    if (
      (key === 'email' && /\S+@\S+\.\S+/.test(value)) ||
      ((key === 'firstName' || key === 'lastName') && value.length > 0) ||
      (key === 'phoneNumber' && value.length > 7 && value.length < 15) ||
      (key === 'vehicleClass' && value !== 'Select...')
    ) {
      this.setState({errors: {...errors, [key]: false}});
    } else {
      this.setState({errors: {...errors, [key]: true}});
      return true;
    }
    return false
  }

  handleToggleChange(vehicleClass, event) {
    const {target: {name, value}} = event;
    if (name === 'activeSwitch') {
      const testType = value === 'leftOption' ? 'preTest' : value === 'middleOption' ? 'fullTest' : null;

      if (testType != null) {
        const {levy, testFee} = this.props.page.config[testType].vehicleClasses[vehicleClass];
        this.setState({
          levy,
          [name]: value,
          testFee,
          vehicleClass
        });
      } else {
        this.setState({[name]: value});
      }

      this.handleErrorCheck(event);
    }
  }
  
  handleOptionChange(event) {
    const {target: {name, value}} = event;

    if ((name === 'email') ||
      ((name === 'firstName' || name === 'lastName') && /^[a-z'\- ]*$/i.test(value)) ||
      (name === 'phoneNumber' && /^[+]{0,1}[0-9]*$/.test(value)) ||
      (name === 'honeyPot')
    ) {
      this.setState({[name]: value});
      this.handleErrorCheck(event);
    }
  }
  
  handleRegistrationChange(event) {
    const {target: {name, value}} = event;
    this.registrationChange(name, value);
  }
  
  registrationChange(name, value) {
    let yearLength = 0;
    if (name === 'registrationYear' && /^[0-9]{0,3}$/.test(value)) {
      this.setState({[name]: value});
      this.registrationErrorCheck(name, value);
      if ((yearLength = value.length) > 1) {
        const maxYearLength = value.charAt(0) === '1' && value > 12 ? 3 : 2;
        if (yearLength === maxYearLength) {
          document.getElementById("registrationCounty").focus();
        }
      }
    } else if (name === 'registrationCounty' && /^[a-zA-Z]{0,2}$/.test(value)) {
      this.setState({[name]: value});
      this.registrationErrorCheck(name, value);
      if ((value.length - yearLength) === 2) {
        document.getElementById("registrationID").focus();
      }
    } else if (name === 'registrationID' && /^[0-9]{0,6}$/.test(value)) {
      this.setState({[name]: value});
      this.registrationErrorCheck(name, value);
    }
  }
  
  handleRegistrationErrorCheck(event) {
    const {target: {value}} = event;
    this.registrationErrorCheck(event.target.name, value);
  }
  
  registrationErrorCheck(name, value) {
    const {errors, registrationCounty, registrationID, registrationYear} = this.state;
    
    if(value === "") {
      this.setState({errors: {...errors, registration: true}});
      return true;
    } else {
      
      if(
        (name === "registrationYear" && value !== '' && registrationCounty !== '' && registrationID !== '') ||
        (name === "registrationCounty" && value !== '' && registrationYear !== '' && registrationID !== '') ||
        (name === "registrationID" && value !== '' && registrationYear !== '' && registrationCounty !== '')
      ) {
        this.setState({errors: { ...errors, registration: false}});
      }
      return false;
    }
  }
  
  handleTimeChange(time) {
    this.setState({time: time.format("HH:mm")});
  }
  
  handleVehicleClassChange(testType, event) {
    const {target: {name, value}} = event;
    const {levy, testFee} = this.props.page.config[testType].vehicleClasses[value];
    this.setState({
      levy,
      [name]: value,
      testFee
    });
    this.handleErrorCheck(event);
  }
  
  renderVehicleClassOptions(vehicleClass, testType) {
    const classes = this.props.page.config[testType].vehicleClasses;
    return (
      <select name="vehicleClass" onChange={this.handleVehicleClassChange.bind(this, testType)} onBlur={this.handleErrorCheck.bind(this)} value={vehicleClass}>
        {Object.keys(classes).map(key => <option key={key} value={key}>{key}</option>)}
      </select>
    )
  }
  
  preFormSubmissionErrorCheckHasError() {
    const errorsUpdate = {};
    
    if(this.state.honeyPot !== "") {
      return true;
    }
    
    if(this.state.activeSwitch !== "rightOption") {
      if(!/\S+@\S+\.\S+/.test(this.state.email)) {
        errorsUpdate["email"] = true;
      }
      if(this.state.firstName.length < 1) {
        errorsUpdate["firstName"] = true;
      }
      if(this.state.lastName.length < 1) {
        errorsUpdate["lastName"] = true;
      }
      if(this.state.phoneNumber.length <= 7 || this.state.phoneNumber.length >= 15) {
        errorsUpdate["phoneNumber"] = true;
      }
      if(this.state.vehicleClass === 'Select...') {
        errorsUpdate["vehicleClass"] = true;
      }
    }
  
    if(this.state.date === null) {
      errorsUpdate["date"] = true;
    }
    if(this.state.registrationID === '' || this.state.registrationCounty === '' || this.state.registrationYear === '') {
      errorsUpdate["registration"] = true;
    }
  
    this.setState({errors: {...this.state.errors, ...errorsUpdate}});
    return Object.values(errorsUpdate).includes(true);
  }
  
  handleFormSubmission(event) {
    event.preventDefault();
    if(!this.preFormSubmissionErrorCheckHasError()) {
      this.setState({submitting: true});
      const {
        activeSwitch,
        date,
        email,
        firstName,
        lastName,
        levy,
        phoneNumber,
        registrationYear,
        registrationCounty,
        registrationID,
        testFee,
        time,
        vehicleClass
      } = this.state;
      
      fetch('https://jrmze1d0cg.execute-api.eu-west-1.amazonaws.com/prod/', {
        method: 'post',
        body: JSON.stringify({
          'name': `${firstName} ${lastName}`,
          'email': email,
          'phoneNumber': phoneNumber,
          'registration': `${registrationYear}-${registrationCounty.toUpperCase()}-${registrationID}`,
          'vehicleClass': vehicleClass,
          'testType': activeSwitch === 'leftOption' ? 'Pre Test' : activeSwitch === 'middleOption' ? 'Full Test' : 'Retest',
          'testDate': date,
          'testTime': time,
          'testFee': testFee,
          'levyFee': levy,
          'totalFee': ((testFee * 1.23) + levy).toFixed(2)
        })
      }).then(() => {
        this.setState({submitting: false, submitted: true});
        setTimeout(() => this.setState({...this.getInitialState(), submitted: false}), 3000);
      });
    }
  }
  
  render() {
    const {
      activeSwitch,
      email,
      errors,
      firstName,
      honeyPot,
      lastName,
      levy,
      phoneNumber,
      registrationYear,
      registrationCounty,
      registrationID,
      testFee,
      submitted,
      submitting,
      vehicleClass
    } = this.state;

    const testType = activeSwitch === 'leftOption' ? 'preTest' : 'fullTest'
    
    return (
      <Column direction="cc">
        
        <ToggleSwitch activeSwitch={activeSwitch} onChange={this.handleToggleChange.bind(this, vehicleClass)} leftOption="Pre Test" middleOption="Full Test" rightOption="Retest" />
  
        <Column className={css.contactInfo} direction="cc">
          <form onSubmit={this.handleFormSubmission.bind(this)}>
            
            <input id="honeyPot" name="honeyPot" className={css.honeyPot} onChange={this.handleOptionChange.bind(this)} value={honeyPot}/>
          
            <Column className={css.section} direction="cc">
              <div className={css.block}>
                <label>Test Date:</label>
                <DatePicker onDateSelected={this.handleDateChange.bind(this)}/>
              </div>
              <p className={[css.error, errors.date ? css.visible : css.hidden].join(" ")}>A valid test date is required</p>
            </Column>
          
            <Column className={css.section} direction="cc">
              <div className={css.block}>
                <label>Test Time:</label>
                <TimePicker onTimeSelected={this.handleTimeChange.bind(this)}/>
              </div>
              <p className={[css.error, css.hidden].join(" ")}>A valid test time is required</p>
            </Column>
          
            <Column className={activeSwitch !== "rightOption" ? css.section : css.displayNone} direction="cc">
              <div className={css.block}>
                <label>Vehicle Class:</label>
                {this.renderVehicleClassOptions(vehicleClass, testType)}
              </div>
              <p className={[css.error, errors.vehicleClass ? css.visible : css.hidden].join(" ")}>A valid vehicle class is required</p>
            </Column>
          
            <Column className={css.section} direction="cc">
              <div className={css.block}>
                <label>Registration:</label>
                <div className={css.registration}>
                  <Row className={css.row}>
                    <Row className={css.registrationItem} direction="cc">
                      <input
                        id="registrationYear"
                        name="registrationYear"
                        placeholder="131"
                        onBlur={this.handleRegistrationErrorCheck.bind(this)}
                        onChange={this.handleRegistrationChange.bind(this)}
                        value={registrationYear}
                      />
                    </Row>
                    <Row className={css.registrationItem} direction="cc">
                      <input
                        id="registrationCounty"
                        name="registrationCounty"
                        placeholder="C"
                        onBlur={this.handleRegistrationErrorCheck.bind(this)}
                        onChange={this.handleRegistrationChange.bind(this)}
                        value={registrationCounty}
                      />
                    </Row>
                    <Row className={css.registrationItem} direction="cc">
                      <input
                        id="registrationID"
                        name="registrationID"
                        placeholder="123456"
                        onBlur={this.handleRegistrationErrorCheck.bind(this)}
                        onChange={this.handleRegistrationChange.bind(this)}
                        value={registrationID}
                      />
                    </Row>
                  </Row>
                </div>
              </div>
              <p className={[css.error, errors.registration ? css.visible : css.hidden].join(" ")}>A valid registration number is required</p>
            </Column>
          
            <Column className={activeSwitch !== "rightOption" ? css.section : css.displayNone} direction="cc">
              <div className={css.block}>
                <label>First Name:</label>
                <input
                  name="firstName"
                  onBlur={this.handleErrorCheck.bind(this)}
                  onChange={this.handleOptionChange.bind(this)}
                  type="text"
                  value={firstName}
                />
              </div>
              <p className={[css.error, errors.firstName ? css.visible : css.hidden].join(" ")}>A valid first name is required</p>
            </Column>
          
            <Column className={activeSwitch !== "rightOption" ? css.section : css.displayNone} direction="cc">
              <div className={css.block}>
                <label>Last Name:</label>
                <input
                  name="lastName"
                  onBlur={this.handleErrorCheck.bind(this)}
                  onChange={this.handleOptionChange.bind(this)}
                  type="text"
                  value={lastName}
                />
              </div>
              <p className={[css.error, errors.lastName ? css.visible : css.hidden].join(" ")}>A valid last name is required</p>
            </Column>
          
            <Column className={activeSwitch !== "rightOption" ? css.section : css.displayNone} direction="cc">
              <div className={css.block}>
                <label>Contact Number:</label>
                <input
                  name="phoneNumber"
                  onBlur={this.handleErrorCheck.bind(this)}
                  onChange={this.handleOptionChange.bind(this)}
                  type="tel"
                  value={phoneNumber}
                />
              </div>
              <p className={[css.error, errors.phoneNumber ? css.visible : css.hidden].join(" ")}>A valid contact number is required</p>
            </Column>
          
            <Column className={activeSwitch !== "rightOption" ? css.section : css.displayNone} direction="cc">
              <div className={css.block}>
                <label>Email Address:</label>
                <input
                  name="email"
                  onBlur={this.handleErrorCheck.bind(this)}
                  onChange={this.handleOptionChange.bind(this)}
                  type="email"
                  value={email}
                />
              </div>
              <p className={[css.error, errors.email ? css.visible : css.hidden].join(" ")}>A valid email address is required</p>
            </Column>
  
            {submitting ?
              <Row direction='cc'>
                Submitting booking request...
                <Loader type='Circles' color='#f26532' height={30} />
              </Row>
              : submitted ?
                <Row direction='cc'>
                  Request submitted successfully <span className={css.checkmark}>&#10004;</span>
                </Row>
                :
                <Row direction='cc'>
                  <button className={css.submitButton} type="submit">Book Your Test</button>
                </Row>
            }
          </form>
          
          <Row className={activeSwitch !== "rightOption" ? css.testPrice : css.displayNone} direction="cc">
            <table>
              <tbody>
              <tr>
                <td>Test Fee:</td>
                <td>{testFee === null ? '' : `${'\u20Ac'} ${(testFee).toFixed(2)}`}</td>
              </tr>
              <tr>
                <td>VAT:</td>
                <td>{testFee === null ? '' : `${'\u20Ac'} ${(testFee * 0.23).toFixed(2)}`}</td>
              </tr>
              <tr>
                <td>RSA Levy:</td>
                <td>{levy === null ? '' : `${'\u20Ac'} ${(levy).toFixed(2)}`}</td>
              </tr>
              <tr>
                <td>
                  <hr/>
                </td>
                <td>
                  <hr/>
                </td>
              </tr>
              <tr>
                <td>Total (inc. VAT):</td>
                <td>{testFee === null ? '' : `${'\u20Ac'} ${((testFee * 1.23) + levy).toFixed(2)}`}</td>
              </tr>
              </tbody>
            </table>
          </Row>
        </Column>
      </Column>
    );
  }
}

BookingForm.propTypes = {
  page: PropTypes.object.isRequired
};

export default BookingForm;
