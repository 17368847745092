import React from 'react';
import PropTypes from "prop-types";
import DayPickerInput from 'react-day-picker/DayPickerInput';
import dateFnsFormat from 'date-fns/format';

import "./index.scss";


const DatePicker = props => {
  
  const invalidDatesEnd = () => {
    const today = new Date();
    const lastInvalidDate = new Date();
    const thursday = 4;
    const friday = 5;
    const saturday = 6;
    
    switch (today.getDay()) {
      case thursday: case friday:
        lastInvalidDate.setDate(today.getDate() + 4); break;
      case saturday:
        lastInvalidDate.setDate(today.getDate() + 3); break;
      default:
        lastInvalidDate.setDate(today.getDate() + 2);
    }
    
    return lastInvalidDate;
  };
  
  return (
    <DayPickerInput
      dayPickerProps={{
        disabledDays: [{daysOfWeek: [0]}, {before: invalidDatesEnd()}],
        fromMonth: new Date()
      }}
      format="E dd/MM/yyyy"
      formatDate={(date, format, locale) => dateFnsFormat(date, format, {locale})}
      inputProps={{readOnly: true}}
      onDayChange={props.onDateSelected}
      placeholder="Select a test date..."
    />
  );
};

DatePicker.propTypes = {
  onDateSelected: PropTypes.func.isRequired
};
export default DatePicker;
