import React from "react";
import PropTypes from "prop-types";
import TimePicker from 'rc-time-picker';
import moment from 'moment';

import "./index.scss";


const TimePickerWrapper = props => {
  
  return (
    <TimePicker
      defaultValue={moment().hour(8).minute(15)}
      disabledHours={() => [0, 1, 2, 3, 4, 5, 6, 7, 13, 17, 18, 19, 20, 21, 22, 23]}
      hideDisabledOptions
      inputReadOnly
      minuteStep={15}
      onChange={props.onTimeSelected}
      showSecond={false}
    />
  );
};

TimePickerWrapper.propTypes = {
  onTimeSelected: PropTypes.func.isRequired
};

export default TimePickerWrapper;